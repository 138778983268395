<template>
  <div class="box22 container" style="background-color: #fff;">
    <!-- <templateTitle title="人员申请列表"></templateTitle> -->
    <!-- 搜索筛选 -->
    <el-form :inline="true" :model="searchVal" class="user-search flex-between" @submit.native.prevent>
      <div>
        <templateTitle title="人员申请列表" :isBack="false"></templateTitle>
      </div>
      <div class="flex">
        <div>
          <el-form-item>
            <el-input
              size="mini"
              v-model="searchVal.name"
              placeholder="请输入用户名"
              clearable
              @keyup.native.enter="search"
              @clear="search"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" plain icon="el-icon-search" @click="search">查询</el-button>
          </el-form-item>
        </div>
      </div>
    </el-form>
    <div class="table-padding">
      <el-table
        :data="tableData"
        ref="table"
        stripe
        border
        style="width: 100%"
        highlight-current-row
        v-loading="loading"
        :header-cell-style="{ color: '#212A33', backgroundColor: '#fafafa' }"
        height="calc(100vh - 4.375rem)"
      >
        <!-- <el-table-column type="index" label="序号"> </el-table-column> -->
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <span>{{ (pageparm.currentPage - 1) * pageparm.pageSize + scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="createdUserName" label="真实姓名">
            </el-table-column> -->
        <el-table-column prop="name" label="用户名" width="220"> </el-table-column>
        <el-table-column prop="createdTime" label="申请时间" width="220"> </el-table-column>
        <el-table-column prop="reason" label="申请理由"> </el-table-column>
        <el-table-column prop="status" label="状态" width="100">
          <template slot-scope="scope">
            <div :class="['status' + scope.row.status]">{{ statusEnum[scope.row.status] }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="审批" width="100">
          <template v-if="scope.row.status == 0" slot-scope="scope">
            <el-button type="text" size="mini" @click="handleAudit(scope.row, 'agree')">接受</el-button>
            <el-button style="color: red;" type="text" size="mini" @click="handleAudit(scope.row, 'refuse')"
              >拒绝</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页组件 -->
    <Pagination v-bind:child-msg="pageparm" @callFather="callFather"></Pagination>
  </div>
</template>
<script>
import { _getUserTenantRecord, _auditUser } from '@/api/modular/supervision/companyMag/applicationList'
import templateTitle from '@/components/templateTitle.vue'
import Pagination from '@/components/Pagination'
export default {
  data() {
    return {
      tableData: [],
      statusEnum: {
        0: '待审核',
        1: '通过',
        2: '拒绝'
      },
      searchVal: {
        name: ''
      }, //搜索字段
      // 分页参数
      pageparm: {
        currentPage: 1,
        pageSize: 10,
        total: null
      },
      loading: false //是显示加载
    }
  },
  created() {
    this.getPage()
  },
  components: {
    templateTitle,
    Pagination
  },
  mounted() {},
  methods: {
    // 获取列表
    getPage() {
      this.loading = true
      const { currentPage, pageSize, total } = this.pageparm
      let params = {
        pageNo: currentPage,
        pageSize: pageSize,
        ...this.searchVal
      }
      _getUserTenantRecord(params).then(res => {
        if (res.code == 200) {
          this.tableData = res.data.rows
          this.pageparm.total = res.data.totalRows

          this.$nextTick(() => {
            this.$refs.table.doLayout()
          })

          if (this.pageparm.total && this.tableData.length == 0 && this.pageparm.currentPage > 1) {
            this.pageparm.currentPage--
            this.getPage()
          }
          this.loading = false
        } else {
          this.loading = false
          this.$message.error(res.message)
        }
      })
    },
    // 审批方法
    handleAudit(value, type) {
      let params
      switch (type) {
        case 'agree':
          params = {
            id: value.id,
            status: 1,
            opinion: '管理员已同意！'
          }
          break

        case 'refuse':
          params = {
            id: value.id,
            status: 2,
            opinion: '管理员拒绝了申请！'
          }
          break

        default:
          break
      }
      // return
      _auditUser(params).then(res => {
        console.log(res, '-----res-----')
        if (res.code == 200) {
          console.log(res, '管理员已同意！')
          this.$message.success('提交成功！')
          this.getPage()
        } else {
          this.$message({
            type: 'info',
            message: res.message
          })
        }
      })
    },
    // 搜索事件
    search() {
      this.pageparm.currentPage = 1
      this.getPage()
    },
    // 分页插件事件
    callFather(parm) {
      this.pageparm.currentPage = parm.currentPage
      this.pageparm.pageSize = parm.pageSize
      this.getPage()
    }
  }
}
</script>
<style lang="scss" scoped>
.status0 {
  color: #ff8000;
}

// .bad {
.status1 {
  color: rgb(60, 170, 56);
}

.status2 {
  color: red;
}
</style>
