/*
 * @Author: awenyjw
 * @Date: 2023-04-27 09:36:30
 * @LastEditors: awenyjw
 * @LastEditTime: 2023-05-10 15:08:48
 * @FilePath: \hc-smartSupervision\src\api\modular\supervision\companyMag\applicationList.js
 * @Description: 
 * 
 */
import {
    axios
} from '@/utils/request'

// 人员申请列表信息
export function _getUserTenantRecord(params) {
    return axios({
        url: '/usertenantrecord/page',
        method: 'get',
        params: params
    })
}
// 审核新成员加入
export function _auditUser(params) {
    return axios({
        url: '/usertenantrecord/audit',
        method: 'post',
        data: params,
    })
}
